import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ActivitiesLog from "shared/components/ActivitiesLog";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import { QUOTES } from "utils/api/graphql/queries/quotes";
import { SUBSCRIPTIONS } from "utils/api/graphql/queries/subscription";
import { SUBSCRIPTION_STATUSES } from "utils/constants";

const SubscriptionActivities = ({ projectId }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);

  const variables = {
    where: {
      project: {
        id: projectId,
      },
    },
  };

  const { loading, data } = useQuery(SUBSCRIPTIONS, {
    variables: {
      where: {
        AND: [
          {
            project: {
              id: projectId,
            },
          },
          {
            OR: [
              { status: SUBSCRIPTION_STATUSES.PENDING },
              { status: SUBSCRIPTION_STATUSES.SIGNED },
            ],
          },
        ],
      },
    },
  });
  const {
    loading: { quotesLoading },
    data: quotesData,
  } = useQuery(QUOTES, {
    variables,
  });

  if (loading || quotesLoading) return <Loading />;

  const subscriptions = data?.subscriptions?.data || [];
  const quotes = quotesData?.quotes?.data || [];

  const activities = sortBy(
    [
      ...quotes.flatMap(({ createdDate }) => ({
        status: SUBSCRIPTION_STATUSES.DRAFT,
        date: createdDate,
      })),
      ...subscriptions.flatMap(({ sendSignatureDate, signedDate, status }) =>
        status === SUBSCRIPTION_STATUSES.PENDING
          ? { date: sendSignatureDate, status: SUBSCRIPTION_STATUSES.PENDING }
          : [
              {
                date: sendSignatureDate,
                status: SUBSCRIPTION_STATUSES.PENDING,
              },
              { date: signedDate, status: SUBSCRIPTION_STATUSES.SIGNED },
            ]
      ),
    ],
    (activity) => moment(activity.date).toDate()
  ).reverse();

  if (!subscriptions.length && !quotes.length) {
    return <div className="no-activity">{t("ACTIVITIES.NO_ACTIVITY")}</div>;
  }

  return <ActivitiesLog activities={activities} />;
};

export default SubscriptionActivities;

import classNames from "classnames";
import dayjs from "dayjs";
import { filter, find, get, last, sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineEventBusy,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import Select from "shared/components/Select";
import Status from "shared/components/Status";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { USERS } from "utils/api/graphql/queries/users";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  PROCESS_TAGS as PROCESS_TAGS_STATUSES,
  USER_TYPES,
} from "utils/constants";

const LOCK_ICON = {
  [true]: <MdOutlineLock size={16} color={COLORS.C_GRAY} />,
  [false]: <MdOutlineLockOpen size={16} color={COLORS.C_GRAY} />,
};

const Projects = ({ projects, translate, tracer, isAssigning, onAssign }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const PROCESS_TAGS = t("PROCESS_TAGS", { returnObjects: true });

  const projectStatus = (project) => {
    const currentStatus = get(
      last(
        sortBy(
          filter(
            project.statuses,
            ({ status }) => get(status,"process.id") === get(tracer, "flow.process.id")
          ),
          "createdDate"
        )
      ),
      "status"
    );
    const statusText =
      (project.expired && PROCESS_TAGS.EXPIRED) ||
      get(currentStatus, "name", PROCESS_TAGS.IN_PROGRESS);
    return (
      <Status
        type="custom"
        style={{
          color: get(currentStatus, "color"),
          background: get(currentStatus, "background"),
        }}
        text={statusText}
      />
    );
  };
  return (
    <div className="projects__wrapper">
      {get(projects, "data", []).map((project, i) => (
        <div className="single-project" key={`project-${i}`}>
          <div className="single-project__detail">
            <div className="single-project__detail--header">
              <span className="product_detail--createdDate">
                {translate.CREATED_AT}
                {dayjs(project.createdDate).format(DATE_TIME_FORMAT)}
              </span>
              {projectStatus(project)}
            </div>
          </div>
          <div className="single-project__author">
            <div
              className={classNames("single-project__author--effectiveDate", {
                expired: project.expired,
              })}
            >
              <MdOutlineEventBusy
                size={16}
                color={project.expired ? COLORS.C_DANGER : COLORS.C_GRAY}
              />
              {dayjs(project.contract.issuanceDate).format(DATE_FORMAT)}
            </div>
            <div className="single-project__author--name">
              {project.author?.id ? (
                <>
                  <span>{translate.ATTRIBUTED_TO}</span>
                  <span className="value">
                    {project.author?.firstname} {project.author?.lastname}
                  </span>
                </>
              ) : (
                <Select
                  type="async"
                  node={{
                    query: USERS,
                    variables: {
                      where: {
                        AND: [
                          { isActive: true },
                          {
                            OR: [
                              {
                                meta: {
                                  type: USER_TYPES.INTERNAL_ADVISOR.type,
                                },
                              },
                              {
                                meta: {
                                  type: USER_TYPES.INTERNAL_SUPERVISOR.type,
                                },
                              },
                              {
                                meta: { type: USER_TYPES.BROKER_EMPLOYEE.type },
                              },
                              { meta: { type: USER_TYPES.BROKER_ADMIN.type } },
                              {
                                meta: {
                                  type: USER_TYPES.BROKER_REPRESENTATIVE.type,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    accessors: {
                      root: "users.data",
                      label: {
                        separator: " ",
                        keys: ["firstname", "lastname"],
                      },
                    },
                  }}
                  placeholder={translate.BROKER_FILTER_PLACEHOLDER}
                  onChange={(value) =>
                    onAssign({ projectId: project.id, authorId: value })
                  }
                  disabled={
                    isAssigning[project.author?.id] ||
                    [PROCESS_TAGS_STATUSES.SIGNED].includes(
                      get(
                        find(
                          project.statuses,
                          ({ status }) =>
                            status?.process?.id ===
                            get(tracer, "flow.process.id")
                        ),
                        "status"
                      )?.tag
                    )
                  }
                  loading={isAssigning[project.author?.id]}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Projects;

import { useTranslation } from "react-i18next";
import Icon from "shared/components/Icon";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_STATUSES } from "utils/constants";
import { formatDate } from "utils/helpers/date";

const mapStatus = (status, TRANSLATION) => {
  const values = {
    [SUBSCRIPTION_STATUSES.DRAFT]: {
      label: TRANSLATION.STATUS.DRAFT,
      icon: <Icon name="convert_to_text" size={24} />,
    },
    [SUBSCRIPTION_STATUSES.PENDING]: {
      label: TRANSLATION.STATUS.SEND,
      icon: <Icon name="gesture_select" size={24} />,
    },
    [SUBSCRIPTION_STATUSES.SIGNED]: {
      label: TRANSLATION.STATUS.SIGNED,
      icon: <Icon name="draw" size={24} />,
    },
    DEFAULT: {
      label: TRANSLATION.STATUS.DRAFT,
      icon: <Icon name="convert_to_text" size={24} />,
    },
  };
  return values[status] || values.DEFAULT;
};

const ActivityItem = ({ activity }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
  const { status, date } = activity;
  const TRANSLATION = t("ACTIVITIES", { returnObjects: true });
  const { label, icon } = mapStatus(status, TRANSLATION);

  return (
    <div className="activities__log--item">
      <div className="icon">{icon}</div>
      <div className="content">
        <div className="line">
          <span className="status">{label} </span>
          {/* {TRANSLATION.WITH}
          <span className="name">{project.author.firstname} {project.author.lastname}</span> */}
        </div>
        <span className="date">{date ? formatDate(date) : "-"}</span>
      </div>
    </div>
  );
};

export default ActivityItem;
